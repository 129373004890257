import React from "react";
import "./Header.scss";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap } from "../../wrapper";

const scaleVariants = {
	whileInView: {
		scale: [0, 1],
		opacity: [0, 1],
		transition: {
			duration: 3,
			ease: "easeInOut",
		},
	},
};

function Header() {
	return (
		<div className="app__header app__flex app__header-modifier">
			{/* <div className="header__image">
				<img className="header__image-img" alt="" />
			</div> */}
			{/* <motion.div
				whileInView={{ x: [-500, 0], opacity: [0, 1] }}
				transition={{ duration: 3 }}
				className="app-header-info"
			>
				<div className="app__header-badge">
					<div className="badge-cmp app__flex">
						<span>👋</span>
						<div style={{ marginLeft: 20 }}>
							<p className="p-text">Hello, I am</p>
							<h1 className="head-text">Fathullah</h1>
						</div>
					</div>
					<div className="tag-cmp app__flex">
						<p className="p-text">Based in Kuala Lumpur.</p>
						<p className="p-text">I eat, sleep and breath React.</p>
					</div>
				</div>
			</motion.div> */}

			<div className="app__header-about">
				<h1 className="app__header-title">Fathullah Harun</h1>
				<p className="app__header-p">
					<hr className="line" />
					Based in Kuala Lumpur. Front-end Developer. I eat, sleep and breath
					React
					<hr className="line2" />
				</p>
			</div>

			{/* <motion.div
				whileInView={{ opacity: [0, 1] }}
				transition={{ duration: 0.5, delayChildren: 0.5 }}
				className="app__header-img"
			>
				<img src={images.profile2} alt="profile_bg" />
				<motion.img
					whileInView={{ scale: [0, 1] }}
					transition={{ duration: 1, ease: "easeInOut" }}
					className="overlay__circle"
					src={images.circle}
					alt="profile_circle"
				/>
			</motion.div> */}
			{/* <motion.div
				variant={scaleVariants}
				whileInView={scaleVariants.whileInView}
				className="app__header-circles"
			>
				{[images.html, images.react, images.css].map((circle, index) => (
					<div className="circle-cmp app__flex" key={`circle-${index}`}>
						<img src={circle} alt="profile_bg" />
					</div>
				))}
			</motion.div> */}
		</div>
	);
}

export default AppWrap(Header, "home");
