import React from "react";
import { About, Footer, Header, Skills, Testimonials, Work } from "./container";
import { motion } from "framer-motion";
import { Navbar } from "./components";
import { Route, Switch } from "react-router-dom";
import "./App.scss";

const App = () => {
	return (
		<div className="app">
			<Switch>
				<Route exact path="/">
					<Navbar />
					<Header />
				</Route>
				<Route path="/about">
					<motion.div
						className=""
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "tween", duration: 3 }}
					>
						<About />
					</motion.div>
				</Route>
				<Route path="/work">
					<motion.div
						className=""
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "tween", duration: 3 }}
					>
						<Work />
					</motion.div>
				</Route>
				{/* <Skills /> */}
				{/* <Testimonials /> */}
				<Footer />
			</Switch>
		</div>
	);
};

export default App;
