import React, { useState } from "react";
import { images } from "../constants";
import "./Navbar.scss";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import ReactTooltip from "react-tooltip";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function Navbar() {
	const [toggle, setToggle] = useState(false);
	const resume = "Click to download";

	return (
		<nav className="app__navbar">
			{/* <div className="app__navbar-logo"> */}
			{/* <img src={images.logo} alt="" /> */}
			{/* </div> */}
			<ul className="app__navbar-links">
				{["about", "work"].map((item) => (
					<li className="app__flex p-text" key={`${item}`}>
						<div />
						<Link to={`/${item}`}>{item}</Link>
					</li>
				))}
				<li className="app__flex p-text resume">
					<div />
					<Tippy content="Click to download">
						<a
							data-tip
							data-for="resume"
							key="resume"
							// href="https://drive.google.com/uc?export=download&id=1IVtNnf4FbdP9l76Wow1pSCQhWPFgf8fc"
							// href="https://drive.google.com/uc?export=download&id=1jLTZIySDdpYgaat4YHYFdP0eVZjmy5Y4"
							// href="https://drive.google.com/uc?export=download&id=1pD8eVj4vDNc_5PeNOu8kGvSktawxf7_O"
							// href="https://drive.google.com/uc?export=download&id=1KYb3QD3rtHoBkiojAOvbn2DTgM4i2hmz"
							href="http://drive.google.com/uc?export=view&id=1Zp6drtt-X2K4jHnAmfAs6xW8XIq8o3Yn"
							download
						>
							Resume
						</a>
					</Tippy>
					{/* <ReactTooltip
						id="resume"
						effect="solid"
						arrowColor="#fff"
						className="resume-tooltip"
					>
						<div className="app__flex p-text">Click to download</div>
					</ReactTooltip> */}
				</li>
				<li className="app__flex p-text">
					<div />
					<Tippy content="Drop me an email">
						<Link
							target="_blank"
							to={{ pathname: "mailto:rajafathullah1@gmail.com" }}
						>
							Contact
						</Link>
					</Tippy>
				</li>
			</ul>
			<div className="app__navbar-menu">
				<HiMenuAlt4 onClick={() => setToggle(true)} />

				{toggle && (
					<motion.div
						whileInView={{ x: [300, 0] }}
						transition={{ duration: 0.85, ease: "easeOut" }}
					>
						<HiX onClick={() => setToggle(false)} />
						<ul className="app__navbar-links">
							{/* {["home", "about", "contact", "work", "resume"].map((item) => (
								<li key={item}>
									<a href={`#${item}`} onClick={() => setToggle(false)}>
										{item}
									</a>
								</li>
							))} */}

							{["about", "work"].map((item) => (
								<li className="app__flex p-text" key={`${item}`}>
									<div />
									<Link onClick={() => setToggle(false)} to={`/${item}`}>
										{item}
									</Link>
								</li>
							))}
							<li className="app__flex p-text">
								<div />
								<Tippy placement="right" content="Click to download">
									<a
										onClick={() => setToggle(false)}
										data-tip
										data-for="resume"
										key="resume"
										href="https://drive.google.com/uc?export=download&id=1IVtNnf4FbdP9l76Wow1pSCQhWPFgf8fc"
										download
									>
										Resume
									</a>
								</Tippy>
								{/* <ReactTooltip
									id="resume"
									effect="solid"
									arrowColor="#fff"
									className="resume-tooltip"
								>
									<div className="app__flex p-text">Click to download</div>
								</ReactTooltip> */}
							</li>
							<li className="app__flex p-text">
								<div />
								<Tippy placement="right" content="Drop me an email">
									<Link
										onClick={() => setToggle(false)}
										target="_blank"
										to={{ pathname: "mailto:rajafathullah1@gmail.com" }}
									>
										Contact
									</Link>
								</Tippy>
							</li>
						</ul>
					</motion.div>
				)}
			</div>
		</nav>
	);
}

export default Navbar;
