import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import { Link } from "react-router-dom";

function About() {
	const [abouts, setAbouts] = useState([]);

	useEffect(() => {
		const query = '*[_type == "abouts"]';

		client.fetch(query).then((data) => setAbouts(data));
	}, []);

	return (
		<motion.div
			className="app__flex app__about "
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "tween", duration: 3 }}
		>
			<h2 className="head-text2">
				{/* I Know that <span>Good Web Apps</span> <br />
				mean <span>Good Business</span> */}
				I'm a software developer who mainly specializes in front-end
				development. I love to create simple yet beautiful web apps with great
				user experience. My <span>tech stack</span> as follows <span>>>></span>
			</h2>

			<div className="app__profiles">
				{abouts.map((about, index) => (
					<motion.div
						whileInView={{ opacity: 1 }}
						whileHover={{ scale: 1.1 }}
						transition={{ duration: 0.5, type: "tween" }}
						className="app__profile-item"
						key={about.title + index}
					>
						<img src={urlFor(about.imgUrl)} alt={about.title} />
						<h2 className="bold-text" style={{ marginTop: 20 }}>
							{about.title}
						</h2>
						<p className="p-text" style={{ marginTop: 10 }}>
							{about.description}
						</p>
					</motion.div>
				))}
			</div>
			<div className="about__home">
				{/* <Link to="/" className="home-modifier">
					Home
				</Link> */}
				<Link to="/work" className="home-modifier">
					Work
				</Link>
			</div>
		</motion.div>
	);
}

export default AppWrap(
	MotionWrap(About, "app__about"),
	"about",
	"app__whitebg"
);
